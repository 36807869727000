import React from "react"
import Banner from "../components/Banner/Banner"
import Header from "../components/Header/Header"
import StepContent from "../components/StepContent/StepContent"
import Product from "../components/Product/Product"
import Video from "../components/Video/Video"
import Gallery from "../components/Gallery/Gallery"
import ContactUs from "../components/ContactUs/ContactUs"

class RootIndex extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <Banner />
        <StepContent id={'process'} />
        <Product id={'product'} />
        <Video id={'factory'} />
        <Gallery />
        <ContactUs />
      </div>
    )
  }
}

export default RootIndex
