import React, { Component } from "react"
import Section from "../Section/Section"
import background from "../../../static/assets/video-bg.png"
import styled from "styled-components"
import media from "../../utils/media"
import get from "lodash/get"
import ReactPlayer from "react-player"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, StaticQuery } from "gatsby"

const VideoWrapper = styled.div`
  ${media.mobile`
    width: 100%;
    height: 30vh;
  `}
  width: 60%;
  height: 50%;
  margin: auto; 
`

class Video extends Component {
  render() {
    const data = get(this, "props.data[0].node")
    return (
      <Section
        id={this.props.id}
        column
        fullHeight
        style={{
          background: `url(${background})`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ textAlign: "center" }}>{data.title}</h1>
        <p style={{ textAlign: "center", marginBottom: 40 }}>
          {data.description}
        </p>
        <VideoWrapper>
          <ReactPlayer
            muted
            url={data.video.file.url}
            playing
            width="100%"
            height="100%"
            controls
          />
        </VideoWrapper>
      </Section>
    )
  }
}

Video.propTypes = {}

export default injectIntl((props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulVideo {
          edges {
            node {
              title
              node_locale
              description
              video {
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
          }
        }
      }
    `}
    render={(contentfulData) => {
      const edges = get(contentfulData, "allContentfulVideo.edges", [])
      const { intl: { locale } } = props
      const data = edges.filter(({ node: { node_locale } }) => locale === node_locale)
      return <Video data={data} {...props} />
    }}
  />
))
