import React, { Component } from "react"
import Button from "../Button/Button"
import Input from "../Input/Input"
import styled from "styled-components"
import media from "../../utils/media"
import { FormattedMessage } from "gatsby-plugin-intl"

const Form = styled.form`
  ${media.mobile`
    width: 90%;
    margin: auto;
    padding: 12px 24px;
    margin-top: 40px;
  `}
  height: 70vh;
  width: 40%;
  background: white;
  position: absolute;
  box-shadow: 7px 7px 18px 1px rgba(0,0,0,0.3);
  margin: 70px 0px;
  padding: 20px 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`

class ContactForm extends Component {
  render() {
    return (
      <Form name="Contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="Contact" />
        <Input type="text" name={"name"} label={<FormattedMessage id="contact_name" />} />
        <Input type="email" name={"email"} label={<FormattedMessage id="contact_email" />} />
        <Input type="text" name={"phoneNumber"} label={<FormattedMessage id="contact_phone_number" />} />
        <Input textarea type="text" name={"message"} label={<FormattedMessage id="contact_message" />} style={{ flex: 1 }} />
        <Button style={{ marginLeft: "auto" }} type='submit'><FormattedMessage id="contact_send_message" /></Button>
      </Form>
    )
  }
}

ContactForm.propTypes = {}

export default ContactForm
