import React from "react"
import styled from "styled-components"
import media from "../../utils/media"

const StyledSection = styled.section`
  ${media.mobile`
    height: ${props => props.fullHeightMobile ? "100vh" : "auto"};
    padding: ${props => props.fullWidthMobile ? "0px" : "40px 20px"};
    ${props => props.mobileColumn && "flex-direction: column;"};
    ${props => props.mobileColumnReverse && "flex-direction: column-reverse;"};
  `}
  height: ${props => props.fullHeight ? "100vh" : "auto"};
  padding: 40px 80px;
  overflow: hidden;
  display: flex;
  flex-direction: ${props => props.column ? "column" : "row"};  
`

export default (props) => (
  <StyledSection {...props} />
)
