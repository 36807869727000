import React, { Component } from "react"
import styled from "styled-components"
import media from "../../utils/media"
import { FormattedMessage } from "gatsby-plugin-intl"

const BadgeNumber = styled.div`
  font-family: 'Montserrat', 'Kanit';
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  padding: 12px;
  font-weight: bold;
  margin-right: 16px;
  color: #FFFFFF;
  text-align: center;
  background: #E74E0B;
`

const Node = styled.div`
  ${media.mobile`
    display: none;
  `}
  position: relative;
  :after {
    content: '';
    height: 4px;
    width: 90%;
    position: absolute;
    top: 43%;
    left: 50px;
    background: #E74E0B;
  }
`

const MobileLabel = styled.h3`
  ${media.mobile`
    flex: 1.2;
    text-align: left;
    display: block;
  `}
  display: none;
`

class Timeline extends Component {
  render() {
    const { steps } = this.props
    return (
      <div style={{ display: "flex" }}>
        {steps && steps.map(({ node }, index) => (
          <Node style={{ flex: index === 1 ? 2 : 1 }}>
            <BadgeNumber>{node.order}</BadgeNumber>
            <span style={{ fontSize: ".3em", verticalAlign: "top" }}>{node.title}</span>
          </Node>
        ))}
        <MobileLabel>All Process In:</MobileLabel>
        <h3 style={{ flex: 1, textAlign: "right" }}>
          <FormattedMessage id="sum_days" />
        </h3>
      </div>
    )
  }
}

Timeline.propTypes = {}

export default Timeline
