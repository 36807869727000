import { css } from "styled-components"

export const sizes = {
  largeScreen: 1440,
  mobile: 1024,
}

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
