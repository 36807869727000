import React, { Component } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import get from "lodash/get"
import Button from "../Button/Button"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import logo from "../../../static/assets/logo.png"
import styled from "styled-components"
import media from "../../utils/media"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import { navigate } from "@reach/router"
import { FiMenu } from "react-icons/fi"

const HeaderWrapper = styled.header`
  ${media.mobile`
    background-color: white;
    flex-direction: column;
    padding: 0px;
  `}
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.2s ease-in-out;
  background: ${props => props.topPosition > 30 && "white"};
  padding: 20px 80px 5px;
  box-shadow: ${props => props.topPosition > 30 && "rgba(0, 0, 0, 0.15) 0px 0px 20px 0px"};
`

const Logo = styled.img`
  ${media.mobile`
    width: 120px;
    padding: 8px;
  `}
  width: 140px;
  padding: 20px;
  margin-bottom: 0px;
`

const MobileMenuIcon = styled.a`
  ${media.mobile`
    position: absolute;
    z-index: 15;
    left: 8px;
    top: 15px;
    font-size: 30px;
    display: flex;
    color: black;
  `}
  display: none;
`

const MenuWrapper = styled.div`
  ${media.mobile`
    flex: none;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease-out;
    width: ${props => props.isOpenMenu ? "100%" : "50%"};
    padding: ${props => props.isOpenMenu ? "20px" : "0px"};
    height: ${props => props.isOpenMenu ? "auto" : "0px"};
    border-top: ${props => props.isOpenMenu ? ".5px solid #e4e4e4" : "none"};
  `}
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
`

const Menus = styled.div`
  ${media.mobile`
    flex-direction: column;
    margin: auto;
    margin-bottom: 20px;
  `}
  display: flex;
  align-items: center;
  flex: 1;
  font-weight: bold;
`

const LanguageSwitcherWrapper = styled.div`
  ${media.mobile`
    display: flex;
    position: initial;
    justify-content: center;
  `}
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 100;
  padding-top: 8px;
  display: flex;
  font-weight: bold;
  position: absolute;
  justify-content: flex-end;
  top: -40px;
`

const Menu = ({ menus, isOpenMenu, toggleMenu }) => (
  <MenuWrapper isOpenMenu={isOpenMenu}>
    {
      menus &&
      <Menus>
        {menus.map(({ node }) => (
          <ScrollLink
            key={node.contentful_id}
            style={{
              color: "black",
              padding: "8px 16px",
              fontSize: "1.15em",
              cursor: "pointer",
            }}
            activeClass="active"
            to={node.path}
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
            onClick={toggleMenu}
          >
            {node.title}
          </ScrollLink>
        ))}
      </Menus>
    }
    <Button onClick={() => navigate("/product/")}>
      <FormattedMessage id="get_quote" />
    </Button>
    <LanguageSwitcherWrapper>
      <LanguageSwitcher />
    </LanguageSwitcherWrapper>
  </MenuWrapper>
)

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      topPosition: 0,
      isOpenMenu: false,
    }
  }


  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll)
  }

  listenToScroll = () => {
    const topPosition =
      document.body.scrollTop || document.documentElement.scrollTop
    this.setState({
      topPosition,
    })
  }

  render() {
    const menus = get(this, "props.data", [])
    const { topPosition, isOpenMenu } = this.state

    return (
      <React.Fragment>
        <HeaderWrapper topPosition={topPosition}>
          <MobileMenuIcon
            href="#"
            onClick={
              () => this.setState((prevState) => ({ isOpenMenu: !prevState.isOpenMenu }))
            }
          >
            <FiMenu />
          </MobileMenuIcon>
          <Link to="/">
            <Logo src={logo} alt="Logo" />
          </Link>
          <Menu
            menus={menus}
            isOpenMenu={isOpenMenu}
            toggleMenu={
              () => this.setState((prevState) => ({ isOpenMenu: !prevState.isOpenMenu }))
            }
          />
        </HeaderWrapper>
      </React.Fragment>
    )
  }
}

export default injectIntl((props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulMenu(sort: {order: ASC, fields: order}) {
          totalCount
          edges {
            node {
              contentful_id
              node_locale
              title
              path
            }
          }
        }
      }
    `}
    render={(contentfulData) => {
      const edges = get(contentfulData, "allContentfulMenu.edges", [])
      const { intl: { locale } } = props
      const data = edges.filter(({ node: { node_locale } }) => locale === node_locale)
      return <Header data={data} {...props} />
    }}
  />
))
