import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import Button from "../Button/Button"
import Section from "../Section/Section"
import styled from "styled-components"
import { FiArrowRight } from "react-icons/fi"
import bannerBG from "../../../static/assets/banner-bg.png"
import media from "../../utils/media"
import { injectIntl } from "gatsby-plugin-intl"

const BannerContainer = styled.div`
    position: relative;
    opacity: ${props => props.active ? 1 : 0};
    transition: all 1s ease-in;
`

const BannerContentWrapper = styled.div`
    ${media.mobile`
      width: 100%;
      justify-content: flex-start;
      margin-top: ${props => props.image ? 4 : 17}em;
      padding: ${props => props.image ? 0 : 20}px;
      margin-left: 0px;
    `}
    position: absolute;
    display: flex;
    flex-direction: column;
    right: ${props => props.image && "0px"};
    margin: auto;
    margin-left: 40px;
    width: ${props => props.image ? 60 : 33}%;
    height: 100vh;
    justify-content: center;
`

const PaginationWrapper = styled.div`
  ${media.mobile`
    justify-content: flex-start;
    margin-top: 17em;
  `}
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
`

class Banner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
    }
  }

  componentDidMount() {
    const banners = get(this, "props.data", [])

    setInterval(() => {
      this.setState((prevState) => {
        if (prevState.activeIndex === banners.length - 1) {
          return { activeIndex: 0 }
        }
        return { activeIndex: prevState.activeIndex + 1 }
      })
    }, 8000)
  }

  render() {
    const banners = get(this, "props.data", [])
    const { activeIndex } = this.state

    return (
      <Section
        fullHeight
        fullHeightMobile
        fullWidthMobile
        column
        style={{
          backgroundImage: `url(${bannerBG})`,
          backgroundSize: "cover",
          backgroundRepeat: "round",
        }}
      >
        {
          banners && banners.map(({ node }, index) => (
            <BannerContainer
              key={node.contentful_id}
              active={activeIndex === index}
            >
              <BannerContentWrapper image>
                <Img
                  alt={node.bannerImage.description}
                  style={{ boxShadow: "-7px 7px 18px 1px rgba(0,0,0,0.3)" }}
                  fluid={node.bannerImage.fluid}
                />
              </BannerContentWrapper>
              <BannerContentWrapper>
                <h1 style={{ marginTop: 0 }}>{node.title}</h1>
                <p>{node.subtitle}</p>
                <Button icon onClick={() => {
                  this.setState((prevState) => {
                    if (prevState.activeIndex === banners.length - 1) {
                      return { activeIndex: 0 }
                    }
                    return { activeIndex: prevState.activeIndex + 1 }
                  })
                }}><FiArrowRight /></Button>
              </BannerContentWrapper>
            </BannerContainer>
          ))
        }
        {
          banners &&
          <div
            style={{
              position: "relative",
            }}
          >
            <PaginationWrapper>
              {banners.map(({ node }, index) => {
                return (
                  <div
                    onClick={() => this.setState({ activeIndex: index })}
                    style={{
                      cursor: "pointer",
                      marginTop: 8,
                      marginBottom: 8,
                      borderRadius: 8,
                      height: 60,
                      width: 4,
                      transition: "all 1s ease-in",
                      background: index === this.state.activeIndex ? "#E74E0B" : "gray",
                    }}
                  >
                  </div>
                )
              })}
            </PaginationWrapper>
          </div>
        }
      </Section>
    )
  }
}

export default injectIntl((props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulBanner(sort: {order: ASC, fields: position}) {
          edges {
            node {
              contentful_id
              node_locale
              title
              subtitle
              position
              bannerImage {
                description
                fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE, quality: 80) {
                 ...GatsbyContentfulFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={(contentfulData) => {
      const edges = get(contentfulData, "allContentfulBanner.edges", [])
      const { intl: { locale } } = props
      const data = edges.filter(({ node: { node_locale } }) => locale === node_locale)
      return <Banner data={data} {...props} />
    }}
  />
))
