import React, { Component } from "react"
import Img from "gatsby-image"
import Button from "../Button/Button"
import Section from "../Section/Section"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"
import styled from "styled-components"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import media from "../../utils/media"
import background from "../../../static/assets/mid-bg-lower.png"
import { navigate } from "@reach/router"

const ProductImg = ({ flex, image }) => (
  <div style={{ flex, padding: 8 }}>
    <Img
      alt={image.description}
      style={{ boxShadow: "-7px 7px 18px 1px rgba(0,0,0,0.3)", borderRadius: 8 }}
      fluid={image.fluid}
    />
  </div>
)

const ImagesWrapper = styled.div`
  ${media.mobile`
    flex-direction: row;
  `}
  flex: 2;
  display: flex;
  height: 50%;
`

class Product extends Component {
  render() {
    const data = get(this, "props.data")

    return (
      <Section
        id={this.props.id}
        fullHeight
        mobileColumnReverse
        style={{
          background: `url(${background})`,
          backgroundRepeat: "round",
        }}
      >
        {data && data.map(({ node }) => [
          <div style={{ flex: 1.6, paddingRight: 16, marginTop: 40 }}>
            <span style={{ padding: "8px 32px", borderRadius: 32, background: "#FAEC8C" }}>
              <FormattedMessage id="we_create_for" />
            </span>
            <h1>{node.title}</h1>
            <p>{node.description}</p>
            <Button onClick={() => navigate("/product/")}>
              <FormattedMessage id="go_to_product" />
            </Button>
          </div>,
          <ImagesWrapper>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <ProductImg flex={1} image={node.images[0]} />
              <ProductImg flex={2} image={node.images[2]} />
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <ProductImg flex={2} image={node.images[1]} />
              <ProductImg flex={1} image={node.images[3]} />
            </div>
          </ImagesWrapper>],
        )}
      </Section>
    )
  }
}

Product.propTypes = {}

export default injectIntl((props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulContentWith4Images {
          edges {
            node {
              contentful_id
              node_locale
              description
              title
              images {
                description
                fluid(maxWidth: 350, resizingBehavior: SCALE, quality: 80) {
                 ...GatsbyContentfulFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={(contentfulData) => {
      const edges = get(contentfulData, "allContentfulContentWith4Images.edges", [])
      const { intl: { locale } } = props
      const data = edges.filter(({ node: { node_locale } }) => locale === node_locale)
      return <Product data={data} {...props} />
    }}
  />
))
