import React, { Component } from "react"
import Img from "gatsby-image"
import Section from "../Section/Section"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import media from "../../utils/media"
import LightBox from "../LightBox/LightBox"

const ImageWrapper = styled.div`
  ${media.mobile`
    width: ${props => props.landscape ? "70%" : "67%"}; 
  `}
  width: ${props => props.landscape ? "32%" : "29.5%"};
  padding: 8px;
`

const GalleryWrapper = styled.div`
  ${media.mobile`
    height: 60vh;
  `}
  height: 80vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: scroll;
`

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      selectedIndex: 0,
    }
  }

  render() {
    const data = get(this, "props.data[0].node")
    const { isModalOpen, selectedIndex } = this.state
    return (
      <Section id={this.props.id} fullHeight column>
        <h1 style={{ textAlign: "left" }}>{data.title}</h1>
        <GalleryWrapper>
          {data.images.map((image, index) =>
            <ImageWrapper
              landscape={image.fluid.aspectRatio > 1}
              onClick={() => this.setState({ isModalOpen: true, selectedIndex: index })}
            >
              <Img
                style={{
                  boxShadow: "7px 7px 18px 1px rgba(0,0,0,0.3)",
                }}
                alt={image.description}
                fluid={image.fluid}
              />
            </ImageWrapper>,
          )}
        </GalleryWrapper>
        <LightBox images={data.images} isOpen={isModalOpen} selectedIndex={selectedIndex} onClose={() => this.setState({ isModalOpen: false, selectedIndex: 0 })} />
      </Section>
    )
  }
}

Gallery.propTypes = {}

export default injectIntl((props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulGallery {
          edges {
            node {
              title
              node_locale
              images {
                description
                fluid(maxWidth: 500, resizingBehavior: SCALE, quality: 80) {
                 ...GatsbyContentfulFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={(contentfulData) => {
      const edges = get(contentfulData, "allContentfulGallery.edges", [])
      const { intl: { locale } } = props
      const data = edges.filter(({ node: { node_locale } }) => locale === node_locale)
      return <Gallery data={data} {...props} />
    }}
  />
))
