import React from "react"
import logo from "../../../static/assets/logo.png"
import { FormattedMessage } from "gatsby-plugin-intl"
import styled from "styled-components"
import media from "../../utils/media"
import { FiMail, FiPhone } from "react-icons/fi"

const ContactWrapper = styled.div`
  ${media.mobile`
    flex-direction: column;
    border-top: 1px solid #cecece;
    padding-top: 20px;
  `}
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
`


const Logo = styled.img`
  ${media.mobile`
    width: 60%;
    padding-right: 0px;
    margin-bottom: 20px;
  `}
  width: 20%;
  height: auto;
  padding-right: 20px;
  margin-bottom: 0px;
`

const LogoWithText = styled.div`
  ${media.mobile`
    flex-direction: column;
    text-align: center;
  `}
  flex: 3;
  display: flex;
  align-items: center;
  text-align: left;
`

const EmailAndPhone = styled.div`
  ${media.mobile`
    text-align: center;
  `}
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

const ContactAddress = () => {
  return (
    <ContactWrapper>
      <LogoWithText>
        <Logo src={logo} alt="Logo" />
        <div>
          <h1 style={{ marginTop: 0, marginBottom: 10, fontSize: "1.6em" }}>
            <FormattedMessage id="rbd_name" />
          </h1>
          <p style={{ margin: 0, fontSize: ".8em" }}>
            <FormattedMessage id="rbd_address" />
          </p>
        </div>
      </LogoWithText>
      <EmailAndPhone>
        <p style={{ marginBottom: 10, fontSize: ".8em" }}><FiMail
          style={{ verticalAlign: "middle" }} /> rcbd2011@gmail.com</p>
        <p style={{ marginBottom: 0, fontSize: ".8em" }}><FiPhone style={{ verticalAlign: "middle" }} /> Tel: 02-4977626-8 | Fax: 02-4977629</p>
        <p style={{ marginBottom: 0, fontSize: ".8em" }}>Mobile: 094-9168791</p>
      </EmailAndPhone>
    </ContactWrapper>
  )
}

ContactAddress.propTypes = {

}

export default ContactAddress
