import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  transition: all 0.2s ease-in;
  ${props => props.icon ? 'padding: 0px;' : 'padding: 8px 30px;'}
  border: none;
  border-radius: 20px;
  ${props => props.icon ? 'width: 40px;' : 'min-width: 120px;'}
  max-width: 200px;
  height: 40px;
  font-size: 0.8em;
  font-family: 'Montserrat', 'Kanit';
  font-weight: bold;
  background-color: #E74E0B;
  color: white;
  outline: none;
  &:hover {
    background: black;
  }
`

export default (props) => (
  <StyledButton {...props} />
)
