import React from "react"
import { changeLocale, IntlContextConsumer } from "gatsby-plugin-intl"

const languageName = {
  "en-US": "EN",
  "th-TH": "TH",
}

const LanguageSwitcher = () => {
  return (
    <div style={{ width: 64, display: "flex", justifyContent: "space-between", color: "black" }}>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map((language, index) => ([
            index !== 0 && "|",
            <a
              key={language}
              onClick={() => changeLocale(language)}
              style={{ color: currentLocale === language ? "#E74E0B" : "black", cursor: "pointer" }}
            >
              {languageName[language]}
            </a>
          ]))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default LanguageSwitcher
