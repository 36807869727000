import React, { Component } from "react"
import Modal from "react-modal"
import Slider from "react-slick"
import Img from "gatsby-image/withIEPolyfill"
import { FiX } from "react-icons/fi"

class LightBox extends Component {
  render() {
    const { images, isOpen, selectedIndex } = this.props

    const settings = {
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: selectedIndex,
      fade: true,
      customPaging: (i) => {
        return (
          <a>
            <Img fluid={images[i].fluid} objectFit={"cover"} style={{ height: '100%' }} />
          </a>
        )
      },
    }
    return (
      <Modal
        isOpen={isOpen}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.8)",
            zIndex: 100,
          },
          content: {
            background: "none",
            border: "none",
            margin: "auto",
            padding: 40,
          },
        }}
      >
        <a onClick={this.props.onClose} style={{ position: "absolute", right: 0, top: 0, color: "white", cursor: "pointer" }}>
          <FiX style={{ width: 30, height: 30 }} />
        </a>
        <Slider {...settings}>
          {images.map((image) => {
            return (
              <div>
                <Img
                  objectFit={"contain"}
                  style={{
                    height: "70vh",
                  }}
                  alt={image.description}
                  fluid={image.fluid}
                />
              </div>
            )
          })}
        </Slider>
      </Modal>
    )
  }
}

LightBox.propTypes = {}

export default LightBox
