import React from "react"
import styled from "styled-components"

const defaultInputStyle = `
   margin-bottom: 1em;
   border: 1px solid #ccc;
   font-size: .75em;
   padding: 8px 16px;
   outline: none;
`

const StyledLabel = styled.label`
  font-size: .75em;
`

const StyledInput = styled.input`
  ${defaultInputStyle}
  border-radius: 32px;
`

const StyledTextArea = styled.textarea`
  ${defaultInputStyle}
  border-radius: 16px;
  resize: none;
`

StyledInput.propTypes = {}

export default (props) => (
  <React.Fragment>
    <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
    {props.textarea ? <StyledTextArea {...props} /> : <StyledInput {...props} />}
  </React.Fragment>
)


