import React from "react"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Button from "../Button/Button"
import Section from "../Section/Section"
import styled from "styled-components"
import Timeline from "../Timeline/Timeline"
import background from "../../../static/assets/mid-bg-upper.png"
import media from "../../utils/media"

const BadgeNumber = styled.div`
  font-family: 'Montserrat', 'Kanit';
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  font-weight: bold;
  margin-right: 16px;
  color: #FFFFFF;
  text-align: center;
  background: #E74E0B;
`
const ContentWrapper = styled.div`
  display: flex;
  margin-bottom: 60px;
  ${media.mobile`
    flex-direction: column;
  `}
  flex-direction: ${props => props.right ? "row-reverse" : "row"};
`

const Description = styled.div`
  ${media.mobile`
    padding: 30px 0px 0px;
    text-align: center;
  `}
  ${props => props.right ? "padding-right: 30px;" : "padding-left: 30px;"}
  flex: 1;
`

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex: .8;
`

const Image = styled(Img)`
  opacity: ${props => props.active ? 1 : 0};
  width: 100%;
  height: ${props => props.active ? "auto" : "0px"};
  transition: all 1s ease-in-out;
  border-radius: 8px; 
  box-shadow: ${props => props.right ? "-7px 7px 18px 1px rgba(0,0,0,0.3)" : "7px 7px 18px 1px rgba(0,0,0,0.3)"};             
`

class Content extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
    }
  }

  componentDidMount() {
    const { node: { images }, index } = this.props
    setInterval(() => {
      this.setState((prevState) => {
        if (prevState.activeIndex === images.length - 1) {
          return { activeIndex: 0 }
        }
        return { activeIndex: prevState.activeIndex + 1 }
      })
    }, 8000 + (index * 1000))
  }

  render() {
    const { node, right } = this.props
    const { activeIndex } = this.state
    return <ContentWrapper right={right}>
      <ImageContainer>
        {node.images.map((image, index) => (
          <Image
            active={activeIndex === index}
            alt={image.description}
            fluid={image.fluid}
          />
        ))
        }
      </ImageContainer>
      <Description right={right}>
        <p style={{ marginTop: 0, marginBottom: 0, fontSize: ".8em" }}>
          <BadgeNumber> {node.order} </BadgeNumber>
          <FormattedMessage id={`${orderNumber[node.order]}_step`} />
        </p>
        <h3>{node.title}</h3>
        <p style={{ marginTop: 10 }}>{node.description}</p>
        <Button>{node.tag}</Button>
      </Description>
    </ContentWrapper>
  }
}


const orderNumber = ["zeroth", "first", "second", "third", "forth"]

const StepContent = (props) => {
  const steps = get(props, "data")
  return (
    <Section
      id={props.id}
      column
      style={{
        backgroundImage: `url(${background})`,
        backgroundPositionY: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <h1 style={{ textAlign: "center" }}>
        <FormattedMessage id="our_process" />
      </h1>
      {
        steps && steps.map(({ node }, index) => (
          <Content node={node} right={index % 2} index={index} />
        ))
      }
      <Timeline steps={steps} />
    </Section>
  )
}

export default injectIntl((props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulStep(sort: {order: ASC, fields: order}) {
          edges {
            node {
              contentful_id
              node_locale
              title
              description
              order
              tag
              images {
                description
                fluid(maxWidth: 600, resizingBehavior: SCALE, quality: 80) {
                 ...GatsbyContentfulFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={(contentfulData) => {
      const edges = get(contentfulData, "allContentfulStep.edges", [])
      const { intl: { locale } } = props
      const data = edges.filter(({ node: { node_locale } }) => locale === node_locale)
      return <StepContent data={data} {...props} />
    }}
  />
))
