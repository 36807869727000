import React, { Component } from "react"
import background from "../../../static/assets/footer-bg.png"
import ContactForm from "../ContactForm/ContactForm"
import ContactAddress from "../ContactAddress/ContactAddress"
import Section from "../Section/Section"

import { compose, withProps } from "recompose"
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyADzIYAQ1FuakNeb3gGR7OZc9lDklga3r0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "80vh", marginRight: -80, marginLeft: -80, marginTop: 20 }} />,
    mapElement: <div style={{ height: "100%", zIndex: -1 }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(() => (
  <GoogleMap defaultZoom={15} defaultCenter={{ lat: 13.7135541, lng: 100.2378337 }} defaultOptions={{
    disableDefaultUI: true,
  }}>
    <Marker position={{ lat: 13.7144, lng: 100.250676 }} style={{ width: 60 }} />
  </GoogleMap>
))

class ContactUs extends Component {
  render() {
    return (
      <Section
        id={this.props.id}
        fullHeight
        column
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          background: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundSize: "contain",
        }}
      >
        <ContactAddress/>
        <div>
          <ContactForm />
          <MyMapComponent />
        </div>
      </Section>
    )
  }
}

export default ContactUs
